import React, { useState, useEffect } from "react";
import { db } from "../../app/service/firebase";
import { collection, addDoc } from "firebase/firestore";
import {
  isMobile,
  isBrowser,
  isSafari,
  isChrome,
  isFirefox,
} from "react-device-detect";
import "./ProgressiveRomanticPage.css";

// Function to fetch IP address from an external API (you can use any other service)
const getIpAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return "Unknown";
  }
};

// Function to get browser info
const getBrowserInfo = () => {
  if (isSafari) return "Safari";
  if (isChrome) return "Chrome";
  if (isFirefox) return "Firefox";
  if (isMobile) return "Mobile";
  return "Unknown";
};

const ProgressiveRomanticPage = () => {
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState("");
  const [isCryPopupVisible, setIsCryPopupVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [hide, setHide] = useState(true);

  const messages = [
    "Error 404: You clicked the wrong button 🤖",
    "You're testing my patience! 😬",
    "One more time and I’ll cry 😭",
    "I dare you to click No again 😤",
  ];

  let audio = new Audio("/audio.mp3");

  useEffect(() => {
    const fetchData = async () => {
      // Fetch user data (IP address, browser type)
      const ip = await getIpAddress();
      const browser = getBrowserInfo();

      // Save the user data to Firestore
      try {
        console.log("trying");
        const docRef = await addDoc(collection(db, "users"), {
          ip: ip,
          browser: browser,
          timestamp_epoch: Date.now(),
          timestamp: new Date().toUTCString(),
        });
        console.log("User data saved to Firestore");
      } catch (error) {
        console.error("Error saving user data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means it runs only once when the component mounts

  // Handle the steps and content change
  const nextStep = () => {
    setStep(step + 1);
  };

  const start = () => {
    audio.currentTime = audio.currentTime + 10;
    audio.play();
    setStep(step + 1);
  };

  const handleNoClick = () => {
    setCount(count + 1);
    if (messages[count]) window.alert(messages[count]);
    else setShowCloseButton(false);
  };

  return (
    <div className="progressive-page">
      <div className="content">
        {step === 0 && (
          <div className="step">
            <p className="message">Are you ready for something special?</p>
            <button onClick={start} className="start-btn">
              Start
            </button>
          </div>
        )}
        {!hide && (
          <>
            {step === 1 && (
              <div className="step">
                <p className="greeting">Hey Shruti,</p>
                <p className="message">
                  I know we haven’t talked much, but there’s something truly
                  magical about you. Your passion for work, your dedication to
                  fitness, and your love for even the smallest things reflect an
                  incredible drive and a spirit that’s uniquely yours...
                </p>
                <button onClick={nextStep} className="next-btn">
                  Next
                </button>
              </div>
            )}
            {step === 2 && (
              <div className="step">
                <p className="message">
                  Can I have your trust for just a moment, to share something
                  from the heart?"🙊{" "}
                </p>
                <button onClick={nextStep} className="next-btn">
                  Maybe
                </button>
              </div>
            )}
            {step === 3 && (
              <div className="step">
                <p className="message">Promise?😏</p>
                <button onClick={nextStep} className="next-btn">
                  Promise
                </button>
              </div>
            )}
            {step === 4 && (
              <div className="step">
                <p className="message">Okay, I trust you 😌</p>
                <div className="buttons">
                  <button onClick={nextStep} className="yes-btn">
                    Click here to Unlock
                  </button>
                </div>
              </div>
            )}
            {step === 5 && (
              <div className="step">
                <p className="message">
                  I truly want to get to know you better, share moments, and see
                  where this connection could take us. Can I have your undivided
                  attention for a little while, and perhaps… if things feel
                  right, then forever?
                </p>
                <div className="buttons">
                  <button onClick={nextStep} className="yes-btn">
                    Yes
                  </button>
                  {showCloseButton && (
                    <button onClick={handleNoClick} className="no-btn">
                      No
                    </button>
                  )}
                </div>
              </div>
            )}
            {step === 6 && (
              <div className="step">
                <p className="message">
                  Just a little about me before you go: I’m a huge chai lover
                  (can’t start my day without it!) I might not be the best at
                  texting (I promise I’m much better in person!, I also feel
                  texting is unproductive :p), and I tend to crack some pretty
                  lame jokes, but hey, they’ll definitely make you smile…
                  eventually. I’ve got tons of interesting stories to share,
                  especially about my love for sunsets.
                  <br />
                  Anyway, I’ll be waiting for your response on Instagram.
                  Looking forward to hearing from you! & maybe I can be the one
                  who makes you smile more than a kitten chasing a ball of yarn.
                </p>
                <button
                  onClick={() =>
                    window.open("https://www.instagram.com", "_blank")
                  }
                  className="submit-btn"
                >
                  Can’t wait to chat! 😊
                </button>
              </div>
            )}
          </>
        )}

        {hide && step === 1 && (
          <div className="step">
            <p className="greeting">Hey Shruti,</p>
            <p className="message">A Text would have been a better idea! :p</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressiveRomanticPage;
