// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCynsxgfpwtKYDgjcZ-dqcxdyeNP32Px7w",
  authDomain: "smrth-tech.firebaseapp.com",
  projectId: "smrth-tech",
  storageBucket: "smrth-tech.appspot.com",
  messagingSenderId: "226436717396",
  appId: "1:226436717396:web:139df69989d883a38406c1",
  measurementId: "G-GRD7TD8R2N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, db };
